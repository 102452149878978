.footer {
	text-align: center;
	background-color: #ffffff;
	color: #333333;
	font-family: Arial, sans-serif;
	border: 1px solid #000;
	border-bottom: none;
	border-radius: 15px 15px 0 0;
	padding: 60px 6.25rem; /* 100px -> 6.25rem */
}

/* Content Section */
.footer-content {
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer-heading {
	font-size: 2.5rem;
	font-weight: 600;
	margin-bottom: 40px;
	width: 70%;
	color: #212529;
}

.footer-button {
	padding: 20px 30px;
	/* text-transform: capitalize; */
	font-weight: 600;
	background-color: #141414;
	color: #ffffff;
	border: none;
	border-radius: 7px;
	font-size: 1rem; /* 16px */
	font-weight: 500;
	cursor: pointer;
	transition: all 0.3s ease;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.footer-button:hover {
	background-color: #222222;
}

/* Navigation Section */
.footer-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 50px;
	/* max-width: 900px; */
	margin-left: auto;
	margin-right: auto;
}

.footer-logo {
	font-size: 1.125rem; /* 18px */
	font-weight: 600;
	color: #212529;
}

.footer-links {
	list-style: none;
	padding: 0;
	display: flex;
	gap: 30px;
	font-size: 1rem; /* 16px */
	color: #212529;
}

.footer-links a {
	color: #333333;
	text-decoration: none;
	transition: color 0.3s ease;
}

.footer-links a:hover {
	color: #000000;
}

/* Social Icons */
.footer-social {
	display: flex;
	gap: 15px;
}

.social-icon {
	color: #333333;
	font-size: 1.2rem; /* 16px */
	text-decoration: none;
	transition: color 0.3s ease;
}

.social-icon:hover {
	color: #000000;
}

/* Bottom Section */
.footer-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.875rem; /* 14px */
	color: #666666;
	padding-top: 20px;
	border-top: 1px solid #ebebeb;
	/* max-width: 900px; */
	margin-left: auto;
	margin-right: auto;
}

.footer-legal {
	display: flex;
	gap: 20px;
}

.footer-legal a {
	color: #666666;
	text-decoration: none;
}

.footer-legal a:hover {
	color: #000000;
}

@media (max-width: 1238px) {
	.footer-heading {
		width: 90%;
	}
}

@media (max-width: 1024px) {
	.footer {
		padding: 3.75rem 3.125rem; /* 60px -> 3.75rem, 50px -> 3.125rem */
	}

	.footer-heading {
		width: 70%;
	}

	.footer-nav {
		flex-direction: column;
	}

	.footer-logo {
		margin-bottom: 1.25rem; /* 20px -> 1.25rem */
	}

	.footer-links {
		flex-direction: column;
		gap: 1.25rem; /* 20px -> 1.25rem */
		margin: 1.25rem 0; /* 20px -> 1.25rem */
	}

	.footer-social {
		margin-bottom: 1.25rem; /* 20px -> 1.25rem */
	}
}

@media (max-width: 830px) {
	.footer-heading {
		width: 80%;
	}
}

@media (max-width: 768px) {
	.footer {
		padding: 3.75rem 1.25rem; /* 60px -> 3.75rem, 20px -> 1.25rem */
	}

	.footer-heading {
		width: 90%;
		font-size: 2rem;
	}

	.footer-button {
		padding: 1rem 1.5rem; /* 16px -> 1rem, 24px -> 1.5rem */
		font-size: 0.875rem; /* 14px -> 0.875rem */
	}

	.footer-nav {
		align-items: center;
	}

	.footer-logo {
		font-size: 1rem; /* 16px -> 1rem */
	}

	.footer-links {
		font-size: 0.875rem; /* 14px -> 0.875rem */
		gap: 1rem; /* 16px -> 1rem */
	}

	.social-icon {
		font-size: 1rem; /* 16px -> 1rem */
	}
}

@media (max-width: 480px) {
	.footer {
		padding: 2.5rem 0.625rem; /* 40px -> 2.5rem, 10px -> 0.625rem */
	}

	.footer-heading {
		width: 90%;
		font-size: 1.5rem; /* 24px -> 1.5rem */
		margin-bottom: 1.875rem; /* 30px -> 1.875rem */
	}

	.footer-button {
		padding: 0.875rem 1.25rem; /* 14px -> 0.875rem, 20px -> 1.25rem */
		font-size: 0.8125rem; /* 13px -> 0.8125rem */
	}

	.footer-nav {
		align-items: center;
	}

	.footer-links {
		flex-direction: column;
		font-size: 0.875rem; /* 14px -> 0.875rem */
		gap: 0.625rem; /* 10px -> 0.625rem */
	}

	.footer-social {
		gap: 0.625rem; /* 10px -> 0.625rem */
		margin-bottom: 1.25rem; /* 20px -> 1.25rem */
	}

	.footer-bottom {
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 1.25rem; /* 20px -> 1.25rem */
	}

	.footer-legal {
		flex-direction: column;
		gap: 0.625rem; /* 10px -> 0.625rem */
	}
}
