.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #000;
	border-top: none;
	padding: 1.25rem 6.25rem;
	border-radius: 0 0 15px 15px;
	margin-bottom: 5rem;
	background-color: #fff;
	position: relative; /* To position the hamburger icon */
}

.logo {
	/* width: 120%; */
	width: 155px;
}

.headerLinks {
	display: flex;
}

.headerLinks > li {
	margin: 0 1.25rem;
	text-transform: uppercase;
	font-weight: 600;
}

.coa {
	border: none;
	background-color: #141414;
	padding: 0.8125rem 1.25rem;
	color: #fff;
	text-transform: uppercase;
	border-radius: 5px;
	transition: all 0.3s ease;
}

.coa:hover {
	background-color: #222222;
}

/* Hamburger menu styles */
.hamburger {
	display: none;
	flex-direction: column;
	cursor: pointer;
	width: 2rem; /* 32px */
	height: 2rem;
	justify-content: space-around;
	transition: all 0.3s ease-in-out;
}

.hamburger .bar {
	width: 100%;
	height: 0.25rem; /* 4px */
	background-color: #000;
	border-radius: 2px;
	transition: all 0.3s ease-in-out;
}

/* Transform the hamburger into an "X" when open */
.hamburger.open .bar:nth-child(1) {
	transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
	opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
	transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive Design */
@media (max-width: 990px) {
	.header {
		padding: 1.25rem 3.125rem;
		display: flex;
		justify-content: space-between;
	}

	/* .logo {
		width: 100%;
	} */

	.headerLinks {
		flex-direction: column;
		align-items: center;
		display: none; /* Hide by default */
	}

	.nav-links {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
	}

	.nav-links.open .headerLinks {
		display: flex; /* Show when open */
		position: absolute;
		top: 100%; /* Below the header */
		left: 0;
		right: 0;
		background-color: #fff;
		padding: 1.25rem 0;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 0 0 15px 15px;
		z-index: 10;
	}

	.headerLinks > li {
		margin: 0.625rem 0;
	}

	.coa {
		width: 100%;
		text-align: center;
	}

	.hamburger {
		display: flex; /* Show hamburger on smaller screens */
	}
}

@media (max-width: 570px) {
	.header {
		padding: 1.35rem;
	}

	.logo {
		width: 140px;
	}

	.coa {
		padding: 0.75rem 0.4rem;
		font-size: 0.7rem;
	}
}

@media (max-width: 420px) {
	a:has(.coa) {
		display: none;
	}

	.header {
		justify-content: center;
	}
}
